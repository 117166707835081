<template>
  <div class="pt-logos">
    <span
      v-if="title"
      class="pt-logos__title"
      :class="[{ 'pt-logos__title--sm': dense }, headerClass]"
    >
      {{ title }}
    </span>
    <ul
      :class="[
        dense ? 'pt-logos__grid' : 'pt-logos__flex',
        { 'border-t border-blue-dark': separator }
      ]"
    >
      <li v-if="customLogo && customLogo.url">
        <NuxtImg
          :key="customLogo.url"
          :src="customLogo.url"
          :alt="customLogo.alternativeText || ''"
          :title="customLogo.alternativeText || ''"
          loading="lazy"
        />
      </li>
      <li v-for="(item, index) in logos" :key="index">
        <NuxtImg
          :key="item.url"
          loading="lazy"
          :src="item.url"
          :alt="item.alternativeText || ''"
          :title="item.alternativeText || ''"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'BannerLogos',
  props: {
    title: {
      type: String,
      default: ''
    },
    logos: {
      type: Array,
      default: () => []
    },
    customLogo: {
      type: Object,
      default: () => ({})
    },
    dense: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      default: 'mb-9'
    },
    separator: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({})
};
</script>
<style src="./style.scss" lang="scss" />
